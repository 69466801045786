<template>
  <el-dialog
    :title="`${id ? '编辑' : '邀请'}服务中心`"
    :visible.sync="show"
    width="600px"
  >
    <el-form v-if="show" :model="form" label-width="auto">
      <el-form-item v-if="!id" label="绑定高能经纪人">
        <el-select v-model="form.customerId" style="width:100%;" placeholder="请选择高能经纪人">
          <el-option
            v-for="item in height"
            :key="item.id"
            :label="item.nickname"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="有效期至">
        <el-date-picker
          v-model="form.expireTime"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="签约公司">
        <el-input v-model="form.legalName" />
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="form.cellphone" />
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import form from '@/mixins/form'
import * as api from '@/api/serviceCenter'

export default {
  mixins: [form],
  props: {
    height: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      api,
      show: false
    }
  },
  methods: {
    uploadImage({ file }) {
      this.upload(file).then(res => {
        this.form.coverUrl = res.data.fileUrl
      })
    }
  }
}
</script>
