import request from '@/utils/axios'

/** 服务中心列表 */
export function getList(params) {
  return request({
    url: '/server/center',
    method: 'get',
    params
  })
}

/** 编辑服务中心 */
export function edit(data) {
  return request({
    url: '/server/center',
    method: 'put',
    data
  })
}

/** 邀请服务中心 */
export function add(data) {
  return request({
    url: '/server/center',
    method: 'post',
    data
  })
}

/** 服务中心降级 */
export function demotion(id) {
  return request({
    url: `/server/center/demote?id=${id}`,
    method: 'put'
  })
}

/** 获取高能经纪人列表 */
export function getAgentList(params) {
  return request({
    url: '/broker',
    method: 'get',
    params
  })
}

/** 详情 */
export function getDetail(id) {
  return request({
    url: `/server/center/detail?id=${id}`,
    method: 'get'
  })
}
