<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.keyword" placeholder="请输入搜索关键词" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="add">邀请服务中心</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="uid"
        label="UID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="agentName"
        label="服务中心名称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="legalName"
        label="签约公司名称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="rewardTotal"
        label="实际奖励总额"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="cellphone"
        label="手机号"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ status[row.status] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="签约时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="expireTime"
        label="过期时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        label="操作"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-row>

            <el-button type="primary" size="small" @click="edit(row.id)">编辑</el-button>

            <el-button type="danger" size="small" @click="demotion(row.id)">降级</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
    <Form ref="form" :height="heightList" />
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/serviceCenter'
import Form from './form'
export default {
  components: { Form },
  mixins: [list],
  data() {
    return {
      api,
      status: {
        0: '禁用',
        1: '启用'
      },
      heightList: []
    }
  },
  created() {
    this.getAgentList()
  },
  methods: {
    getAgentList() {
      this.api.getAgentList({ page: 1, pageSize: 999 }).then(res => {
        this.heightList = res.data.list
      })
    },
    demotion(id) {
      this.$confirm('此操作将删除服务中心账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.demotion(id).then(() => {
          this.$message({
            message: '降级成功',
            type: 'success'
          })
        })
        this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消降级'
        })
      })
    }
  }
}
</script>
